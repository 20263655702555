import axios from 'axios';
import router from "@/router";

// type : 0= (common apis) 1 =(user apis)

export default class ApiService {
  StorageObjUserRef = localStorage.user_reference;
  StorageObjClient = localStorage.client_info ? localStorage.client_info : '';
  StorageObjMasterBucket = localStorage.masterBucketName ? localStorage.masterBucketName : '';
  client = this.StorageObjClient.maa1 ? this.StorageObjClient.maa1 : '';
  user = localStorage.user;

  HeaderParam = {
    "Access-Control-Allow-Origin": "*",
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'content-type': 'application/json',
    'Accept': 'application/json',
  };

  AuthenticatedUserHeader = {
    "Access-Control-Allow-Origin": "*",
    'Access-Control-Allow-Methods': 'GET,PUT,POST,DELETE,PATCH,OPTIONS',
    'content-type': 'application/json',
    'Accept': 'application/json',
    'Authorization': "Bearer " + this.StorageObjUserRef,
    user: this.user
  };




  commonapiall = (url, params, method, type, item) => {
    return axios(url, {
      baseURL: location.origin,
      method: method,
      headers: params,
      data: item,
    })
      .then(response => {
        if (response.data.status == 403) {
          // if (type === 1) {
          this.userlogout();
          // }
        }
        return response.data;

      })
      .catch(error => {
        throw error;
      });
  };

  userlogin(item) {
    let url = 'api/u/userlogin';
    return this.commonapiall(url, this.HeaderParam, 'POST', 0, item);

  }

  userlogout = (item) => {

    const URL = 'api/v1/u/userlogout';
    return axios(URL, {
      method: 'POST',
      baseURL: location.origin,
      headers: this.AuthenticatedUserHeader,
      data: item,
    })
      .then(response => {
        localStorage.clear();
        router.push({ path: '/login' });
        // window.location.reload();
        //  router.push({ path: '/' });
        return response.data;
      })
      .catch(error => {
        // window.location.reload();
        throw error;
      });
  }

  clientlist(item) {
    let url = 'api/v1/u/clientlist';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  //-> START OF DID NUMBER APIs
  getDIDNumberByStatus(item) {
    let url = 'api/v1/u/getDIDNumberByStatus';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getServiceProvider(item) {
    let url = 'api/common/getStates';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  createDIDNumber(item) {
    let url = 'api/v1/u/addDIDNumber';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }
  updateDIDNumber(item) {
    let url = 'api/v1/u/updateDIDNumber';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }
  // -> END OF DID NUMBER APIs

  //-> START OF Support Ticktes APIs
  getTicketsByStatus(item) {
    let url = 'api/v1/u/getTicketsByStatus';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getTicketsMessage(item) {
    let url = 'api/v1/u/getTicketMessages';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }
  // -> END OF Support Ticktes APIs

  createclient(item) {
    let url = 'api/v1/u/createclient';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }
  getStates(item) {
    let url = 'api/common/getStates';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getParties(item) {
    let url = 'api/common/getParties';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }
  getcities(item) {
    let url = 'api/common/getCities';
    return this.commonapiall(url, this.HeaderParam, 'POST', 0, item);
  }

  saveclientsstep(item) {
    let url = 'api/v1/u/saveclientsstep';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  saveclientsstepthird(item) {
    let url = 'api/v1/u/saveclientsstepthird';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  getclientdetails(item) {
    let url = 'api/v1/u/getclientdetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  updateclientconfig(item) {
    let url = 'api/v1/u/updateclientconfig';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  getallplans(item) {
    let url = 'api/v1/u/getallplans';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }
  addNewPlan(item) {
    let url = 'api/v1/u/addNewPlan';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }
  getplandetails(item) {
    let url = 'api/v1/u/getplandetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  getmasterplans(item) {
    let url = 'api/common/getmasterplans';
    return this.commonapiall(url, this.HeaderParam, 'POST', 0, item);
  }

  getplancomponents(item) {
    let url = 'api/v1/u/getplancomponents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  getloksabhalist(item) {
    let url = 'api/v1/u/getloksabhalist';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  createloksabha(item) {
    let url = 'api/v1/u/createloksabha';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  getvidhansabhalist(item) {
    let url = 'api/v1/u/getvidhansabhalist';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  createvidhansabha(item) {
    let url = 'api/v1/u/createvidhansabha';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  getcorporationlist(item) {
    let url = 'api/v1/u/getcorporationlist';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  createcorporation(item) {
    let url = 'api/v1/u/createcorporation';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  getzillaparishadlist(item) {
    let url = 'api/v1/u/getzillaparishadlist';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  createzillaparishad(item) {
    let url = 'api/v1/u/createzillaparishad';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  getpanchayatsamitilist(item) {
    let url = 'api/v1/u/getpanchayatsamitilist';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  createpanchayatsamiti(item) {
    let url = 'api/v1/u/createpanchayatsamiti';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  getgrampanchayatlist(item) {
    let url = 'api/v1/u/getgrampanchayatlist';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  creategrampanchayat(item) {
    let url = 'api/v1/u/creategrampanchayat';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  getReservations(item) {
    let url = 'api/common/getReservations';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getloksabha(item) {
    let url = 'api/common/getloksabha';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getvidhansabha(item) {
    let url = 'api/common/getvidhansabha';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getzillaparishad(item) {
    let url = 'api/common/getzillaparishad';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  gettaluka(item) {
    let url = 'api/common/gettaluka';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getgrampanchayat(item) {
    let url = 'api/common/getgrampanchayat';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getpanchayatsamiti(item) {
    let url = 'api/common/getpanchayatsamiti';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getdistrict(item) {
    let url = 'api/common/getdistrict';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getComponentName(item) {
    let url = 'api/common/getComponentName';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  doPlanConfigUpdate(item) {
    let url = 'api/v1/u/doPlanConfigUpdate';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  getexistingplancomponents(item) {
    let url = 'api/v1/u/getexistingplancomponents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }
  createClientPlanComponent(item) {
    let url = 'api/v1/u/createClientPlanComponent';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);

  }
  createPlanConfig(item) {
    let url = 'api/v1/u/createPlanConfig';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  getTaxInvoices(item) {
    let url = 'api/v1/u/getTaxInvoices';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 0, item);
  }

  getTaxInvoiceComponent(item) {
    let url = 'api/v1/u/getTaxInvoiceComponent';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getClientListOnly(item) {
    let url = 'api/common/getClientListOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getPaymentReceipts(item) {
    let url = 'api/v1/u/getPaymentReceipts';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getLiveBalance(item) {
    let url = 'api/v1/u/getLiveBalance';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getbrandsforchain(item) {
    let url = 'api/v1/u/getbrandsforchain';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  addClientFund(item) {
    let url = 'api/v1/u/addClientFund';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }
  getClientFund(item) {
    let url = 'api/v1/u/getClientFund';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getBillingCompany(item) {
    let url = 'api/common/getBillingCompany';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item);
  }

  getClientUsersDetails(item) {
    let url = 'api/v1/u/getClientUsersDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item)
  }
  getClientUsersOnly(item) {
    let url = 'api/v1/u/getClientUsersOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item)
  }
  createUpdateClientUserRole(item) {
    let url = 'api/v1/u/createUpdateClientUserRole';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item)
  }
  removeAssignedRoles(item) {
    let url = 'api/v1/u/removeAssignedRoles';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item)
  }
  getUserList(item) {
    let url = 'api/v1/u/getUserList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item)
  }
  getTitles() {
    let url = 'api/common/getTitles';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'GET', 1)
  }
  createUpdateUser(item) {
    let url = 'api/v1/u/createUpdateUser';
    /* axios.defaults.baseURL = location.origin;
    return axios.post(url, {
      baseURL: location.origin,
      method: 'POST',
      headers: this.AuthenticatedUserHeader,
      data: item,
    })
      .then(response => {
        if (response.data.status == 403) {
          // if (type === 1) {
            this.userlogout();
          // }
        }
        return response.data;

      })
      .catch(error => {
        throw error;
      }); */
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item)
  }
  getRequestForPlanChange(item) {
    let url = 'api/v1/u/getRequestForPlanChange';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item)
  }
  addUpdateClientDocuments(item) {
    let url = 'api/v1/u/addUpdateClientDocuments';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, item)
  }
  getClientDocumentDetails(payload) {
    let url = 'api/v1/u/getClientDocumentDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getClientServiceSettings(payload) {
    let url = 'api/v1/u/getClientServiceSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addClientServiceSettings(payload) {
    let url = 'api/v1/u/addClientServiceSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getClientDebitDetails(payload) {
    let url = 'api/v1/u/getClientDebitDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addComponentDebitAction(payload) {
    let url = 'api/v1/u/addComponentDebitAction';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getAreaList(payload) {
    let url = 'api/v1/u/getAreaList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  assignPlanToclient(payload) {
    let url = 'api/v1/u/assignPlanToclient';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getComponentList(payload) {
    let url = 'api/common/getComponentList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  importAreaData(payload) {
    let url = 'api/v1/u/importAreaData';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getAllCities(item) {
    let url = 'api/common/getAllCities';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'Get', 1, item);
  }
  addUpdateArea(payload) {
    let url = 'api/v1/u/addUpdateArea';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getClientKarykartaDetails(payload) {
    let url = 'api/v1/u/getClientKarykartaDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getAllDefaultTunes(payload) {
    let url = 'api/v1/u/getAllDefaultTunes';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  createUpdateAllDefaultTunes(payload) {
    let url = 'api/v1/u/createUpdateAllDefaultTunes';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  importLoksabhaData(payload) {
    let url = 'api/v1/u/importLoksabhaData';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  importVidhansabhaData(payload) {
    let url = 'api/v1/u/importVidhansabhaData';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getPincodeList(payload) {
    let url = 'api/v1/u/getPincodeList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addUpdatePincode(payload) {
    let url = 'api/v1/u/addUpdatePincode';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  importPincodeData(payload) {
    let url = 'api/v1/u/importPincodeData';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getTemplateEvents(payload) {
    let url = 'api/v1/u/getTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getTemplateActionsOnly(payload) {
    let url = 'api/common/getTemplateActionsOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'GET', 1, payload);
  }
  addUpdateTemplateEvents(payload) {
    let url = 'api/v1/u/addUpdateTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getLoginAsClient(payload) {
    let url = 'api/v1/u/getLoginAsClient';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getAllWhatsAppSettings(payload) {
    let url = 'api/v1/u/getAllWhatsAppSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getTemplateEventsOnly(payload) {
    let url = 'api/common/getTemplateEventsOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  createUpdateWhatsAppSettings(payload) {
    let url = 'api/v1/u/createUpdateWhatsAppSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  importWhatsAppSettings(payload) {
    let url = 'api/v1/u/importWhatsAppSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  copywhatsAppSettingToOtherClient(payload) {
    let url = 'api/v1/u/copywhatsAppSettingToOtherClient';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  doActionClientPlanComponentUpdate(payload) {
    let url = 'api/v1/u/doActionClientPlanComponentUpdate';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getPaymentsFromClientDetails(payload) {
    let url = 'api/v1/u/getPaymentsFromClientDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  updatePaymentsFromClientStatus(payload) {
    let url = 'api/v1/u/updatePaymentsFromClientStatus';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getWhatsAppTemplateSettings(payload) {
    let url = 'api/v1/u/getWhatsAppTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getAllTemplateOnly(payload) {
    let url = 'api/common/getAllTemplateOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addUpdateClientwhatsAppTemplates(payload) {
    let url = 'api/v1/u/addUpdateClientwhatsAppTemplates';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getTemplateActions(payload) {
    let url = 'api/v1/u/getTemplateActions';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);

  }
  getTemplateDescriptions(payload) {
    let url = 'api/v1/u/getTemplateDescriptions';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getAllTemplateDescriptions(payload) {
    let url = 'api/v1/u/getAllTemplateDescriptions';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  isAlreadyPresentTemplateDescriptionId(payload) {
    let url = 'api/v1/u/isAlreadyPresentTemplateDescriptionId';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  isAlreadyPresentTemplateName(payload) {
    let url = 'api/v1/u/isAlreadyPresentTemplateName';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getDistrictList(payload) {
    let url = 'api/v1/u/getDistrictList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addUpdateDistrict(payload) {
    let url = 'api/v1/u/addUpdateDistrict';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  importDistrictData(payload) {
    let url = 'api/v1/u/importDistrictData';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addUpdateEventWhatsAppTemplateSettings(payload) {
    let url = 'api/v1/u/addUpdateEventWhatsAppTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCurrentTemplateEvents(payload) {
    let url = 'api/v1/u/getCurrentTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCurrentWPTemplateEvents(payload) {
    let url = 'api/v1/u/getCurrentWPTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCurrentEmailTemplateEvents(payload) {
    let url = 'api/v1/u/getCurrentEmailTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addUpdateEventEmailTemplateSettings(payload) {
    let url = 'api/v1/u/addUpdateEventEmailTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getClientListOnlyExceptGivenId(payload) {
    let url = 'api/common/getClientListOnlyExceptGivenId';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getWebBotList(payload) {
    let url = 'api/common/getWebBotList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }

  regeneratePaymentReceipt(payload) {
    let url = 'api/v1/u/regeneratePaymentReceipt';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);

  }

  getActiveUsers(item) {
    let url = 'api/common/getActiveUsers';
    return this.commonapiall(url, this.HeaderParam, 'POST', 0, item);
  }
  assignTicket(payload) {
    let url = 'api/v1/u/assignTicket';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getMyAndTeamAssignTicket(payload) {
    let url = 'api/v1/u/getMyAssignAndTeamTicketList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  chat(payload) {
    let url = 'api/v1/u/chat';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  closeTicket(payload) {
    let url = 'api/v1/u/closeTicket';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  triggercalltomeranetaclients(payload) {
    let url = 'api/v1/u/triggercalltomeranetaclients';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getRCSTemplateSettings(payload) {
    let url = 'api/v1/u/getRCSTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getAllRCSTemplateOnly(payload) {
    let url = 'api/common/getAllRCSTemplateOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  isAlreadyPresentRCSTemplateName(payload) {
    let url = 'api/v1/u/isAlreadyPresentRCSTemplateName';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addUpdateClientRCSTemplates(payload) {
    let url = 'api/v1/u/addUpdateClientRCSTemplates';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  copyRCSSettingToOtherClient(payload) {
    let url = 'api/v1/u/copyRCSSettingToOtherClient';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCurrentRCSTemplateEvents(payload) {
    let url = 'api/v1/u/getCurrentRCSTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addUpdateEventRCSTemplateSettings(payload) {
    let url = 'api/v1/u/addUpdateEventRCSTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getAdminTemplateActions(payload) {
    let url = 'api/v1/u/getAdminTemplateActions';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getAdminTemplateActionsOnly(payload) {
    let url = 'api/common/getAdminTemplateActionsOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'GET', 1, payload);
  }
  getAdminWhatsAppTemplateEventsOnly(payload) {
    let url = 'api/common/getAdminWhatsAppTemplateEventsOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getAdminRCSTemplateEventsOnly(payload) {
    let url = 'api/common/getAdminRCSTemplateEventsOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getAdminTemplateEvents(payload) {
    let url = 'api/v1/u/getAdminTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addUpdateAdminTemplateEvents(payload) {
    let url = 'api/v1/u/addUpdateAdminTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCurrentAdminTemplateEvents(payload) {
    let url = 'api/v1/u/getCurrentAdminTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCurrentAdminWPTemplateEvents(payload) {
    let url = 'api/v1/u/getCurrentAdminWPTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCurrentAdminEmailTemplateEvents(payload) {
    let url = 'api/v1/u/getCurrentAdminEmailTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCurrentAdminRCSTemplateEvents(payload) {
    let url = 'api/v1/u/getCurrentAdminRCSTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addUpdateAdminEventWhatsAppTemplateSettings(payload) {
    let url = 'api/v1/u/addUpdateAdminEventWhatsAppTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addUpdateAdminEventEmailTemplateSettings(payload) {
    let url = 'api/v1/u/addUpdateAdminEventEmailTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCurrentAdminSMSTemplateEvents(payload) {
    let url = 'api/v1/u/getCurrentAdminSMSTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addUpdateAdminEventSMSTemplateSettings(payload) {
    let url = 'api/v1/u/addUpdateAdminEventSMSTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCurrentSMSTemplateEvents(payload) {
    let url = 'api/v1/u/getCurrentSMSTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addUpdateEventSMSTemplateSettings(payload) {
    let url = 'api/v1/u/addUpdateEventSMSTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCompaniesList(payload) {
    let url = 'api/v1/u/getCompaniesList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  createUpdateCompanies(payload) {
    let url = 'api/v1/u/createUpdateCompanies';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getcompanyListOnly(payload) {
    let url = 'api/common/getcompanyListOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCompaniesSenderIdList(payload) {
    let url = 'api/v1/u/getCompaniesSenderIdList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  createUpdateCompaniesSenderId(payload) {
    let url = 'api/v1/u/createUpdateCompaniesSenderId';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getClientUsersOnlyExceptRole(payload) {
    let url = 'api/v1/u/getClientUsersOnlyExceptRole';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getLanguages(item) {
    let url = 'api/common/getLanguages';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'GET', 0, item);
  }

  copyTemplateActionToOtherClient(payload) {
    let url = 'api/v1/u/copyTemplateActionToOtherClient';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addUpdateAdminEventRCSTemplateSettings(payload) {
    let url = 'api/v1/u/addUpdateAdminEventRCSTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getClientCompaniesList(payload) {
    let url = 'api/v1/u/getClientCompaniesList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  createUpdateClientCompanies(payload) {
    let url = 'api/v1/u/createUpdateClientCompanies';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getVotersDesigantionList(payload) {
    let url = 'api/v1/u/getVotersDesigantionList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  createUpdateVotersDesigantion(payload) {
    let url = 'api/v1/u/createUpdateVotersDesigantion';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCompaniesSenderIdOnly(payload) {
    let url = 'api/common/getCompaniesSenderIdOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getImageUploadDetails(payload) {
    let url = 'api/v1/u/getImageUploadDetails';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  createUpdateUploadedImage(payload) {
    let url = 'api/v1/u/createUpdateUploadedImage';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getWebBotTemplateSettings(payload) {
    let url = 'api/v1/u/getWebBotTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getAllWebBotTemplateOnly(payload) {
    let url = 'api/v1/u/getAllWebBotTemplateOnly';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  isAlreadyPresentWebBotTemplateName(payload) {
    let url = 'api/v1/u/isAlreadyPresentWebBotTemplateName';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addUpdateClientWebBotTemplates(payload) {
    let url = 'api/v1/u/addUpdateClientWebBotTemplates';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  copyWebBotSettingToOtherClient(payload) {
    let url = 'api/v1/u/copyWebBotSettingToOtherClient';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  createUpdateWhatsAppNumber(payload) {
    let url = 'api/v1/u/createUpdateWhatsAppNumber';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getClientsWhatsappNumber(payload) {
    let url = 'api/v1/u/getClientsWhatsappNumber';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getchatbotListing(payload) {
    let url = "api/v1/u/getchatbotListing";
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  createUpdateChatBotIds(payload) {
    let url = 'api/v1/u/createUpdateChatBotIds';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getLanguagesExceptHindiAndEnglish(payload) {
    let url = 'api/common/getLanguagesExceptHindiAndEnglish';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getTemplateDescriptionOfSubSequenceIds(payload) {
    let url = 'api/v1/u/getTemplateDescriptionOfSubSequenceIds';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCurrentWebBotTemplateBody(payload) {
    let url = 'api/v1/u/getCurrentWebBotTemplateBody';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCurrentWebBotAppTemplate(payload) {
    let url = 'api/v1/u/getCurrentWebBotAppTemplate';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getMaximumTemplateDescriptionCount(payload) {
    let url = 'api/v1/u/getMaximumTemplateDescriptionCount';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCurrentMultiflowTempList(payload) {
    let url = 'api/v1/u/getCurrentMultiflowTempList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCurrentWebBotsAppMultiflowTemplates(payload) {
    let url = 'api/v1/u/getCurrentWebBotsAppMultiflowTemplates';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  deleteLastMultiFlowTemplate(payload) {
    let url = 'api/v1/u/deleteLastMultiFlowTemplate';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  createUpdateTemplateDescription(payload) {
    let url = 'api/v1/u/createUpdateTemplateDescription';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addWebBotFlow(payload) {
    let url = 'api/v1/u/addWebBotFlow';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getWebBotFlowData(payload) {
    let url = 'api/v1/u/getWebBotFlowData';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getBannerImageList(payload) {
    let url = 'api/v1/u/getBannerImageList';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  createUpdateBannerImages(payload) {
    let url = 'api/v1/u/createUpdateBannerImages';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getWebBotFlowSettingData(payload) {
    let url = 'api/v1/u/getWebBotFlowSettingData';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCurrentWebBotTemplateSettings(payload) {
    let url = 'api/v1/u/getCurrentWebBotTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addUpdateWebBotFlowSettingData(payload) {
    let url = 'api/v1/u/addUpdateWebBotFlowSettingData';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCurrentPushNotificationEvents(payload) {
    let url = 'api/v1/u/getCurrentPushNotificationEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addUpdateEventpnTemplateSettings(payload) {
    let url = 'api/v1/u/addUpdateEventpnTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  addUpdatecommunicatingToTemplateSettings(payload){
    let url = 'api/v1/u/addUpdatecommunicatingToTemplateSettings';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCurrentCommunicatingToTemplateEvents(payload){
    let url = 'api/v1/u/getCurrentCommunicatingToTemplateEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getCurrentTemplateClientEvents(payload){
    let url = 'api/v1/u/getCurrentTemplateClientEvents';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  removeClientLogo(payload){
    let url = 'api/v1/u/removeClientLogo';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  getClientAppVersion(payload){
    let url = 'api/v1/u/getClientAppVersion';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
  updateClientAppVersion(payload){
    let url = 'api/v1/u/updateClientAppVersion';
    return this.commonapiall(url, this.AuthenticatedUserHeader, 'POST', 1, payload);
  }
}